<template>
  <div class="row login">
    <div class="col xl12">
      <div class="image-overlay"></div>
      <v-carousel
        :show-arrows="false"
        :hide-delimiter-background="true"
        :hide-delimiters="true"
        :continuous="true"
        :interval="7000"
        :cycle="true"
      >
        <v-carousel-item
          v-for="(image, index) in carouselImages"
          :key="index"
          :src="image"
          cover
          eager
        >
          <template v-slot:default>
            <div class="d-flex fill-height justify-center align-center">
              <v-img
                :src="image"
                cover
                :lazy-src="image"
                class="carousel-image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
              </v-img>
            </div>
          </template>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="logo">
      <img src="/images/logohe.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carouselImages: ["/images/Background-login.png"],
    };
  },
};
</script>

<style lang="scss">
.login {
  position: unset;
  .v-carousel {
    height: calc(100vh - 20px) !important;
  }
  .carousel-image {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .login {
    position: relative;
    .v-carousel {
      height: 450px !important;
    }
  }
}

@media only screen and (max-width: 888px) {
  .logo {
    right: 0;
  }

  .side-nav-login {
    left: unset !important;
  }

  .image-overlay {
    left: 0;
  }
}
</style>

<style lang="scss">
.login {
  position: unset;
  .v-carousel {
    height: calc(100vh - 20px) !important;
  }
}
@media only screen and (max-width: 768px) {
  .login {
    position: relative;
    .v-carousel {
      height: 450px !important;
    }
  }
}
</style>
<style scoped lang="scss">
@media only screen and (max-width: 768px) {
  //mobile
  .image-overlay {
    height: 450px !important;
  }
  .logo {
    top: 0px;
    right: 0px;
    bottom: unset !important;
    left: unset !important;

    img {
      border-top-right-radius: 0px !important;
      border-top-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }
}
.logo {
  position: absolute;
  z-index: 1000;
  left: 50px;
  bottom: 60px;
  img {
    width: 166px;
    height: 166px;
    // border-radius: 50%;
  }
}
img {
  width: 100%;
  height: calc(100vh - 20px);
  object-fit: contain;
}
.image-overlay {
  position: absolute;
  height: calc(100vh - 20px);
  z-index: 99;
  width: 100%;
  background: -webkit-linear-gradient(
    235.26deg,
    #6d1006cb 0%,
    #6d100678 40.5%,
    #ffe6ad 94%,
    #ffe6ad 100%
  );
}
</style>
