<template>
  <div>
    <WrapperTableLine class="agentReq-wrapper-line">
      <AgentRequest
        :isBootomDivederHidden="true"
        :horizontal="!this.$vuetify.display.mobile"
        :agentRequest="agentRequest"
        :isAdmin="isAdmin"
      />
    </WrapperTableLine>
  </div>
</template>

<script>
import WrapperTableLine from "./WrapperTableLine.vue";
import AgentRequest from "./AgentRequest.vue";

export default {
  emits: ["set-current-expand-btn"],
  components: {
    WrapperTableLine,
    AgentRequest,
  },
  props: {
    agentRequest: {
      type: Object,
    },
    isAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      icon: "mdi mdi-eye-outline",
    };
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.table-line {
  min-width: 120px;
  max-width: 140px;
}

.agentReq-wrapper-line {
  display: flex;
  justify-content: space-between;
  align-items: v-bind(alignWrapper);
  hr {
    width: 57px;
    color: #ececec;
    transform: rotate(90deg);
    border: solid 1px #ececec;
  }
}

@media only screen and (max-width: 768px) {
  .table-line {
    min-width: 200px;
  }
}

.agentReq-date-value {
  color: #757575;
  margin-top: -6px;
}

.agentReq-content {
  color: $secondaryTextColor;
  .customer-description {
    color: black;
  }
}
</style>
