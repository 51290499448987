<template>
  <tr v-for="invoice in invoicesList" :key="invoice.docId">
    <td>{{ invoice?.curdate }}</td>
    <td>{{ invoice?.duedate }}</td>
    <td>{{ calculateBacklogDays(invoice) }}</td>
    <td>{{ invoice?.ord }}</td>
    <td>{{ invoice?.docno }}</td>
    <td class="remarks-col">{{ truncateText(invoice?.remarks) }}</td>
    <!-- <td>₪{{ invoice?.totalprice?.toLocaleString() }}</td> -->
    <td>
      <v-checkbox
        multiple
        class="invoice-price-checkbox"
        v-model="customSum"
        :label="'₪' + invoice?.totalprice?.toLocaleString()"
        :value="{ docId: invoice.docId, totalprice: invoice.totalprice }"
      ></v-checkbox>
    </td>
    <td>
      <DownloadInvoiceBtn :docId="invoice.docId" />
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import { truncateText } from "@/utilities.js";
import DownloadInvoiceBtn from "./DownloadInvoiceBtn.vue";

export default {
  emits: ["updateCustomSum"],
  components: { DownloadInvoiceBtn },
  props: {
    invoicesList: {
      type: Array,
      default: () => [],
    },
    invoiceFilterMode: {
      type: String,
      default: "allInvoices",
    },
  },
  mounted() {
    if (this.invoiceFilterMode === "allInvoices") {
      this.customSum = this.formatPriceList(this.invoicesList);
    } else if (this.invoiceFilterMode === "openInvoices") {
      this.filterOpenInvoicesSum();
    } else {
      this.customSum = [];
    }
  },
  data() {
    return {
      customSum: [],
    };
  },
  watch: {
    invoiceFilterMode(mode) {
      if (mode === "allInvoices") {
        this.customSum = this.formatPriceList(this.invoicesList);
      } else if (mode === "openInvoices") {
        this.filterOpenInvoicesSum();
      } else {
        this.customSum = [];
      }
    },
    customSum() {
      const totalPrices = this.customSum.map((item) => item.totalprice);
      this.$emit("updateCustomSum", totalPrices);
    },
  },
  methods: {
    filterOpenInvoicesSum() {
      const filterOpenInvoices = this.invoicesList.filter(
        (invoice) => invoice.isOpen
      );
      this.customSum = this.formatPriceList(filterOpenInvoices);
    },
    formatPriceList(invoicesList) {
      return invoicesList.map((invoice) => ({
        docId: invoice.docId,
        totalprice: invoice.totalprice,
      }));
    },
    calculateBacklogDays(invoice) {
      return invoice.duedate
        ? moment().diff(moment(invoice.duedate, "DD/MM/YYYY"), "days")
        : "";
    },
    truncateText(text) {
      return truncateText(text, 100);
    },
    formatDate(senddate) {
      return moment(senddate).format("DD/MM/YY");
    },
  },
};
</script>

<style lang="scss">
.invoice-price-checkbox {
  height: 50px !important;
  .v-label {
    color: black !important;
    font-size: $mediumTextSize;
  }
}
</style>

<style scoped lang="scss">
tr {
  height: 70px;
}
td {
  color: $orderLineColor;
  font-size: $mediumTextSize;
  text-align: center !important;
}
</style>
