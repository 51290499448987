<template>
  <div class="table-info-wrapper">
    <label v-if="title" class="title-label"> {{ title }}</label>
    <span class="value"> {{ value }}</span>
    <span v-if="subValue" class="value"> {{ subValue }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "⠀",
    },
    subValue: {
      type: String,
    },
    titleLabelColor: {
      type: String,
      default: "#434343",
    },
  },
};
</script>
<style scoped lang="scss">
.table-info-wrapper {
  display: flex;
  flex-direction: column;
  font-size: $mediumTextSize;
  text-align: center;

  .title-label {
    color: v-bind(titleLabelColor);
    // font-weight: bold;
  }

  .value {
    color: $tableValueColor;
  }
}
</style>
