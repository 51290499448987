<template>
  <div>
    <WrapperTableLine
      class="order-wrapper-line"
      :isBtnExpand="isBtnExpand"
      :hightExpand="`${modalHeight}px`"
    >
      <div
        style="width: 100%"
        class="d-flex align-center text-center justify-space-between"
      >
        <TableInfoLine class="table-line" :value="custDes" />
        <TableInfoLine class="table-line flex-2" :value="customerAddress" />
        <TableInfoLine class="table-line" :value="order.ord + ''" />
        <TableInfoLine
          class="table-line"
          :value="order.Customer.DistLine.linedes"
        />
        <TableInfoLine class="table-line" :value="lineDays.join(', ')" />
        <TableInfoLine class="table-line" :value="fomartOrderDate" />
        <TableInfoLine
          class="table-line"
          :value="order?.User?.name ?? order.Customer.Agent.agentname"
        />
        <TableInfoLine
          class="table-line"
          :title="`${formatPrice(numberOfCartons)} ${$t(
            'orders.table.columns.cartons'
          )}`"
          :value="`₪${formatPrice(priceIncludeTaxSum)}`"
        />
      </div>
      <TableActions
        mode="order"
        :agnetInfo="order.Customer.Agent"
        :customerInfo="order.Customer"
        :id="orderId"
        :status="order?.Status"
        :cust="order.Customer.cust"
        :isBtnExpand="isBtnExpand"
        @expand-btn="expandBtn"
        @close-expand="$emit('close-expand')"
        :backgroundColor="'#5c7be9'"
        @setHeight="setHeight"
      />
    </WrapperTableLine>
  </div>
</template>

<script>
import moment from "moment";
import TableActions from "./TableActions.vue";
import TableInfoLine from "./TableInfoLine.vue";
import WrapperTableLine from "./WrapperTableLine.vue";
import { getNextDays } from "../../dates";
import { formatPrice, truncateText } from "../../utilities";

export default {
  emits: ["set-current-expand-btn", "close-expand"],
  components: {
    WrapperTableLine,
    TableInfoLine,
    TableActions,
  },
  props: {
    order: {
      type: Object,
    },
    orderId: {
      type: Number,
    },
    currentExpandBtn: {
      type: Number,
    },
    tableLineBackground: {
      type: String,
    },
  },
  data() {
    return {
      icon: "mdi mdi-eye-outline",
      modalHeight: "",
    };
  },
  computed: {
    customerAddress() {
      const city = this.order?.Customer?.city;
      const address = this.order?.Customer?.address;
      if (city && address)
        return truncateText(
          city + ", " + address,
          25
        );
      return null;
    },
    lineDays() {
      return getNextDays(this.order.Customer.DistLine);
    },
    fomartOrderDate() {
      return moment(this.order.duedate).format("DD/MM/YYYY");
    },
    priceIncludeTaxSum() {
      return (
        this.priceBeforeTaxSum +
        this.priceBeforeTaxSum * (this.order.tax / 100)
      );
    },
    priceBeforeTaxSum() {
      return this.order.OrderLines.reduce((acc, line) => {
        return acc + line.partPrice * line.quant;
      }, 0);
    },
    numberOfCartons() {
      if (this.order?.OrderLines) {
        return this.order.OrderLines.reduce((acc, line) => {
        return acc + line.quant;
      }, 0);
      }
      return 0;
    },
    partOrderWeightSum() {
      if (this.order?.OrderLines) {
        return this.order.OrderLines.reduce((acc, orderLine) => {
          return acc + orderLine?.Part?.patrweight ?? 0;
        }, 0);
      }
      return 0;
    },
    custDes() {
      if (this.order.Customer.custdes.length >= 15) {
        return this.order.Customer.custdes.slice(0, 15) + "...";
      }
      return this.order.Customer.custdes;
    },
    isBtnExpand() {
      if (this.currentExpandBtn === this.order.ord) return true;
      return false;
    },
    alignWrapper() {
      if (this.isBtnExpand) return "start";
      return "center";
    },
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
    setHeight(setHeight) {
      this.modalHeight = setHeight + 20;
    },
    expandBtn() {
      if (!this.isBtnExpand) {
        this.$emit("set-current-expand-btn", this.order.ord);
      } else {
        this.$emit("set-current-expand-btn", 0);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.table-line {
  min-width: 120px;
  max-width: 160px;
}

.order-wrapper-line {
  display: flex;
  justify-content: space-between;
  align-items: v-bind(alignWrapper);
  background: v-bind(tableLineBackground);
}

.v-icon {
  cursor: pointer !important;
}

@media only screen and (max-width: 1024px) {
  .order-wrapper-line {
    width: fit-content;
  }

  .table-line {
    min-width: 200px;
  }
}
</style>
