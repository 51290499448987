import { createStore } from "vuex";
import AuthModule from "./modules/Auth";
import Orders from "./modules/Orders";
import AgentRequests from "./modules/AgentRequests";
import Customers from "./modules/Customers";
import ControlPanel from "./modules/ControlPanel";
import Invoices from "./modules/Invoices";
import Parts from "./modules/Parts";

const store = createStore({
  modules: {
    //namespaced
    AuthModule,
    Orders,
    AgentRequests,
    Parts,
    Customers,
    ControlPanel,
    Invoices,
  },
  state() {
    //data
    return {
      loading: false,
      openMenu: true,
      isMobile: false,
      isTablet: false,
      isLaptop: false,
      isDesktop: false,
      isOpenMobileSideBar: false,
      isShowDesktopHome: false,
      mobileNavSelectedOptionText: "",
      version: "1.0.15",
      toastMessage: "",
      toastColor: "",
      isShowToast: false,
    };
  },
  mutations: {
    setIsShowToast(state, mode) {
      state.isShowToast = mode;
    },
    setToastMessage(state, message) {
      state.toastMessage = message;
    },
    setToastColor(state, status) {
      switch (status) {
        case "success":
          state.toastColor = "green darken-1";
          break;
        case "error":
          state.toastColor = "red darken-1";
          break;
        case "warning":
          state.toastColor = "yellow darken-1";
          break;
        case "info":
          state.toastColor = "blue darken-1";
          break;
        case "":
          state.toastColor = "";
          break;
      }
    },
    setLoading(state, mode) {
      state.loading = mode;
    },
    setMobileNavText(state, selectedText) {
      state.mobileNavSelectedOptionText = selectedText;
    },
    toggleMenu(state) {
      state.openMenu = !state.openMenu;
    },
    setMenuToOpen(state) {
      state.openMenu = true;
    },
    setMenuToClose(state) {
      state.openMenu = false;
    },
    setIsMobile(state, value) {
      state.isMobile = value;
    },
    setIsTablet(state, value) {
      state.isTablet = value;
    },
    setIsLaptop(state, value) {
      state.isLaptop = value;
    },
    setIsDesktop(state, value) {
      state.isDesktop = value;
    },
    setIsOpenMobileSideBar(state, value) {
      state.isOpenMobileSideBar = value;
    },
    setIsShowDesktopHome(state, value) {
      state.isShowDesktopHome = value;
    },
  }, //methods for update the state, only here! not in actions!!!
  actions: {
    startLoading(context) {
      context.commit("setLoading", true);
    },
    stopLoading(context) {
      context.commit("setLoading", false);
    },
    ShowToast(context, { message, status }) {
      console.log(message, status);
      context.commit("setToastColor", status);
      context.commit("setIsShowToast", true);
      context.commit("setToastMessage", message);
      setTimeout(() => {
        context.commit("setToastColor", "");
        context.commit("setIsShowToast", false);
        context.commit("setToastMessage", "");
      }, 5000);
    },
  },
  getters: {
    getMobileNavText(state) {
      return state.mobileNavSelectedOptionText;
    },
    isMobile(state) {
      return state.isMobile;
    },
    isTablet(state) {
      return state.isTablet;
    },
    isLaptop(state) {
      return state.isLaptop;
    },
    isDesktop(state) {
      return state.isDesktop;
    },
    isLoading(state) {
      return state.loading;
    },
    getAppVersion(state) {
      return state.version;
    },
    isOpenMobileSideBar(state) {
      return state.isOpenMobileSideBar && (state.isMobile || state.isTablet);
    },
    isShowDesktopHome(state) {
      return state.isShowDesktopHome;
    },
    isShowToast(state) {
      return state.isShowToast;
    },
    toastColor(state) {
      return state.toastColor;
    },
    toastMessage(state) {
      return state.toastMessage;
    },
  },
});

export default store;
