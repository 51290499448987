
import { he } from "vuetify/lib/locale";

export default {
  direction: "rtl",
  ...he,
  LoginPage: {
    loginFormTitle: "מערכת סוכנים הבלקן",
    userInputPlaceHolder: "הכניסו את מספר הפלאפון שלכם",
    enterPhoneNumberOrMail: "מספר פלאפון",
    enterCode: "הכנס את הקוד שקיבלת מהפלאפון / מייל",
    welcome: " ברוכים הבאים לממשק הסוכנים של מאפה הבלקן.",
    passwordPlaceholder: "סיסמה..",
    missingPhoneOrMail: "יש להזין מספר פלאפון",
    errorLoginServerSide: "ארעה שגיאה בניסיון ההתחברות",
    emptyOtpInput: "שדה הקוד אינו יכול להיות ריק",
    mandatoryFieldError: "שדה חובה",
    loginButton: "קבל קוד לכניסה",
    eneter: "כניסה",
    forgetPassButton: "שכחת סיסמה?",
  },
  Header: {
    controlPanel: "לוח בקרה",
    tommorowOrders: "הזמנות למחר",
    openTommorowOrders: "לקוחות למחר",
    pendingItems: "נושאים לטיפול/ יעדים",
    myorders: "הזמנות",
    activeorders: "הזמנות פתוחות",
    finances: "כספים     ",
    activefinances: "חשבוניות פתוחות",
    cunstomers: "לקוחות",
    activeCustomers: "לקוחות פעילים",
    Returns: "והחזרות",
    tabFinances: "חשבוניות",
    hello: "שלום",
    logout: "התנתקות",
    switchAgentProfile: "החלפת פרופיל",
    nirotekwebsite: "לאתר גוגלי",
    logourl: "/images/logohe.png",
  },

  Footer: {
    contactTitle: "לעזרה בכל נושא",
    safetyText: "האתר מאובטח באמצעות SSL",
  },

  ProductWidget: {
    SKU: 'מק"ט',
    description: "תאור",
    partFamily: "מש. מוצר",
    basePrice: "מחירון",
    discount: "הנחה%",
    finalPrice: "נטו",
    orderNumber: "הזמנה",
    reference: "הזמנתכם",
    quantity: "כמות",
    sumPrice: "סכום",
  },
  Products: {
    clearAll: "נקה הכל",
    overFiftyProducts:
      "יוצגו רק 50 תוצאות ראשונות, ניתן להשתמש במסננים לתוצאות נוספות",
    moreProductsAvailable:
      "ניתן למצוא מוצרים נוספים בעת שימוש בסינונים בראש העמוד.",
    noProductsWereFound: "לא נמצאו מוצרים להצגה",
    productItem: {
      sku: 'מק"ט',
      category: "מש.מוצר",
      description: "תאור",
      addition: "הוספה",
      AvailableInStock: "זמין למלאי",
      outOfStock: "אזל המלאי",
      unitOfSale: "יח מכירה",
      width: "רוחב",
      height: "גובה",
      length: "אורך",
      weight: "משקל",
      status: "סטטוס מוצר",
      volume: "נפח",
      moreInfo: "מידע נוסף למוצר",
    },
  },
  ProductModal: {
    alreadyInCart: "מוצר זה קיים בעגלה",
    notCustPart: "לידיעתך, מוצר זה אינו קיים בקבוצת המוצרים שלך.",
    orderLine: "שורת הזמנה",
    note: "הערה למוצר",
    save: "הוסף לסל",
    cancel: "ביטול",
    inbox: "האם לארוז?",

    quantity: "כמות גליונות:",
    gilyonLength: "אורך הגליון..",
    gilyonWidth: "רוחב הגליון..",

    galilLength: "אורך הגליל במטרים..",
    galilCutWidth: 'רוחב לחיתוך בס"מ..',
    total: 'סה"כ רוחב מצטבר',
    cm: 'ס"מ',
    validWidths: "רוחבי גלילים אפשריים:",
    numOfCycles: "מס' מחזורים:",
    invalidTotalWidth:
      "סכום רוחבי הפרוסות לא תואם את הרוחבים האפשריים ולכן צפוי פחת של ",
    unbox: "לא ארוז",
    boxed: "ארוז",
    sheets: "גליונות",
    galilee: "גלילים",
    notvalidWidth: 'סה"כ רוחב לא אפשרי - רוחב מקסימלי',
    numberCuts: 'רוחב פרוסה בס"מ',
    numberCycles: "מספר פרוסות",
    phatAlert: "יש לתאם עם התפי",
    duplicateCutsAlert: "לא ניתן לחזור על רוחב פרוסה יותר מפעם אחת",
    inboxAlert: "הכמות הסופית של הגיליונות תאושר על ידי התפי",
    // width
  },

  SingelDoc: {
    duedate: "מועד אספקה",
    PurchaseOrderNumber: "מס' הזמנתכם",
    contact: "איש קשר",
    status: "סטטוס",
    contactTooltip: "ניתן לשנות איש קשר רק לאחר ביצוע ההזמנה",
    Remarks: "הערות",
    maxLetters: "מקסימום 120 תווים",
    customerName: "שם לקוח"
  },

  SingleOrder: {
    duedate: "מועד אספקה",
    OrderNumber: "הזמנה",
    shippingCode: "קו חלוקה",
    PurchaseOrderNumber: "מס' הזמנתכם",
    contact: "איש קשר",
    status: "סטטוס",
    orderDetails: " פריטים בהזמנה",
    Remarks: "הערות",
    SendOrder: "שליחת הזמנה ",
    ProductionDate: "תאריך יצירה",
    orderNumber: "מס' הזמנה",
    sent: "נשלחה",
    Update: "עריכת הזמנה",
    inpPreparation: "בהכנה",
    registered: "נקלטה",
    maxLetters: "מקסימום 120 תווים",
    ordersEmpty: "הזמנתך ריקה.",
    viewDeliveryDoc: "צפייה בתעודה",
    savedBeforeSendOrder: "ההזמנה לא נשמרה לפי השליחה",
    contactTooltip: "ניתן לשנות איש קשר רק לאחר ביצוע ההזמנה",
    orderSummaryTitle: 'סה"כ להזמנה',
    orderCountItems:  "מוצרים",
    orderBoxesCount: "קרטונים",
    priceBeforeTax: 'מחיר לפני מע"מ',
    priceIncludeTax: 'מחיר כולל מע"מ',
    tax: 'מע"מ',
    finishEdit: "סיום עריכה",
    cancelEdit: "ביטול עריכה",
  },

  generalTable: {
    columns: {
      actions: "פעולות",
    },
  },

  tommorowOrdersCusts: {
    table: {
      tommorowcustomersTitle: "לקוחות למחר",
      customersMadeTommorowOrder: "לקוחות שביצעו הזמנה למחר",
      columns: {
        tommorowOrderStatus: "סטטוס הזמנה למחר",
        customer: "לקוח",
        address: "כתובת",
        distLine: "קו חלוקה",
        distLineDays: "ימי חלוקה",
        customerTypeStatus: "סטטוס",
        contactInfo: "יצירת קשר",
        agent: "סוכן",
        lastOrder: "הזמנה אחרונה",
        balance: "יתרת זכות/ חובה",
      },
    },
  },

  customers: {
    table: {
      columns: {
        customer: "לקוח",
        address: "כתובת",
        distLine: "קו חלוקה",
        distLineDays: "ימי חלוקה",
        customerTypeStatus: "סטטוס",
        contactInfo: "יצירת קשר",
        agent: "סוכן",
        lastOrder: "הזמנה אחרונה",
        balance: "יתרת זכות/ חובה",
      },
    },
  },

  serviceCalls: {
    table: {
      actions: {
        accpet: "אישור",
        reject: "דחייה",
      },
    },
  },

  invocies: {
    table: {
      columns: {
        invoiceDate: "תאריך חשבונית",
        dueDate: "תאריך פירעון",
        backLogDays: "ימי פיגור",
        order: "הזמנה",
        invoice: "חשבונית",
        details: "פרטים",
        price: "סכום",
        currency: "מט.",
        download: "הורדת חשבונית",
      },
    },
  },

  SingelInvoice: {
    invoiceNumber: "חשבונית",
    invoiceDetails: "פריטים בחשבונית",
    refundRequest: "יצירת בקשת החזרה",
    sendRefundRequest: "שליחת בקשת החזרה",
    cancelRefundRequest: "ביטול בקשת החזרה",
    printCopy: "הפקת עותק חשבונית",
    addQuant: "הוספה להחזרה",
    refundReason: "סיבת החזרה...",
    printModalTitle: "בקשתך נקלטה בהצלחה",
    printModalContent: 'המסמך המבוקש ישלח בדקות הקרובות לדוא"ל',
    printModalCloseBtn: "סגירה",
  },

  Categories: {
    title: "קטגוריות מוצרים",
    favorites: "מועדפים",
    allProducts: "כל המוצרים",
    myProducts: "המוצרים שלי",
    viewOrder: "צפיה בהזמנה »",
    langFieldName: "familyname",
  },

  BaseSearch: {
    search: "חיפוש",
    placeholder: "חיפוש לפי תאריך, מס. חשבונית..",
  },

  sideBar: {
    TomorrowOrders: {
      title: "הזמנות למחר",
    },
    agentRequests: {
      title: "בקשות סוכן",
    },
    seeAll: "הצג הכל",
    Invoice: {
      title: "חשבונית",
    },
    Order: {
      title: "הזמנה",
    },
    Category: {
      title: "מוצרים",
    },
    orders: {
      title: "הזמנות",
      orderStatus: "הזמנות למחר",
      showAll: "כל ההזמנות",
    },
    customers: {
      title: "כל הלקוחות",
      showAll: "הצג הכל",
      titleListOne: "חשבוניות וזיכויים",
      titleListTwo: "בקשות החזרת סחורה",
    },
    invoices: {
      title: "חשבוניות",
    },
    finances: {
      title: "כספים",
      showAll: "גיול חובות",
    },
    cart: {
      title: "הזמנה חדשה",
    },
  },
  orders: {
    myOrders: "ההזמנות שלי",
    addressForDelivery: "כתובת למשלוח",
    searchBySKU: "חיפוש לפי מק”ט, מס’ הזמנה",
    sum: "סכום",
    status: "סטטוס",
    productionDate: "תאריך יצירה",
    docNumber: "הזמנה",
    deliveryCertificate: "תעודת משלוח",
    DeliveryCertificatenNotProvided: "תעודת משלוח טרם סופקה",
    dueDate: "תאריך אספקה",
    reference: "הזמנתכם",
    table: {
      columns: {
        customer: "לקוח",
        address: "כתובת",
        order: "הזמנה",
        distLine: "קו חלוקה",
        distLineDays: "ימי חלוקה",
        orderDate: "תאריך הזמנה",
        agent:'בוצע ע"י',
        kg: 'ק"ג',
        cartons: "יח'",
      },
    },
  },

  home: {
    welcome: "ברוכים הבאים לאתר ההזמנות של נירעותק",
    start: "אפשר להתחיל",
  },
  orderSuccModal: {
    title: "הזמנך נקלטה בהצלחה",
    orderNumber: "מספר הזמנה",
    alertText:
      'ייתכן שמחלקת תפ"י תבצע התאמות נוספות, נעדכן אותך במידה ויהיה שינוי.',
    watchOrder: "לצפייה בהזמנה",
    processingYourRequest: "מטפלים בפניתך",
    continueOrdering: "המשך להזמין",
  },

  downloadButtonWidget: {
    buttonText: "הורדת קובץ",
  },

  filtersWidget: {
    dateLabel: "מתאריך",
    ordersAndInvoicesDateLabel: "תאריך יצירה",
  },

  CartWidget: {
    title: "ניהול בקשות סוכנים",
    emptyCartTitle: "עגלת הקניות שלך ריקה...",
    allRequests: "כל הבקשות",
    startOrderBtn: "התחל להזמין",
    orderSummaryTitle: "לסיכום הזמנה",
    waitingAgentRequest: "בקשות ממתינות לאישור"
  },
};
