<template>
  <TableWrapper
    class="table-wrapper d-flex flex-column ga-3"
    mode="controlPanel"
    :sumCount="agentRequestsList.length"
    :title="$t('CartWidget.allRequests')"
    icon="mdi mdi-bell"
  >
    <AgentRequestTableLine
      class="mb-3"
      v-for="agentRequest in agentRequestsList"
      :key="agentRequest.id"
      :isAdmin="getUserRole?.agentapp"
      :agentRequest="agentRequest"
    />
  </TableWrapper>
</template>

<script>
import AgentRequestTableLine from "./AgentRequestTableLine.vue";
import TableWrapper from "./TableWrapper.vue";
import { mapGetters } from "vuex";

export default {
  components: { AgentRequestTableLine, TableWrapper },
  props: ["agentRequestsList"],
  data() {
    return {
      currentExpandBtn: 0,
    };
  },
  methods: {
    setCurrentExpandBtn(orderId) {
      this.currentExpandBtn = orderId;
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "AuthModule/getUserRole",
    }),
  },
};
</script>

<style lang="scss">
.table-wrapper {
  .table-lines {
    padding: 10px;
    overflow-y: scroll;
  }
}
</style>
