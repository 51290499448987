<template>
  <footer id="footer-container" :class="[positionStyle]">
    <div
      v-if="$route.name === 'Category' && isMobile"
      id="footer-bottom-category-container"
      class="wrapper"
    >
      <CartSummary />
      <v-btn class="summary-btn" @click="openMobileSideBar">סיכום הזמנה</v-btn>
    </div>
    <div v-else id="footer-bottom-part">
      <v-container class="container">
        <div id="footer-bottom-part-container">
          <div
            style="width: 100vw"
            class="d-flex justify-content-between justify-space-between align-center"
          >
            <div id="footer-bottom-part-text-container">
              <p class="d-flex ga-2 align-center">
                <i class="material-icons right">verified_user </i>
                {{ $t("Footer.safetyText") }}
              </p>
            </div>
            <div id="footer-bottom-part-logos-container">
              <CreditModal
                v-if="isCreditModalOpen"
                @forceModalClose="isCreditModalOpen = false"
              />
              <a
                v-if="
                  isAuth && $store.getters.isMobile && $route.name !== 'loginPage'
                "
                @click.prevent="logOutUser"
                href=""
                class="horizontal-divider"
              >
                {{ $t("Header.logout") }}</a
              >
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import CartSummary from "../helpers/CartSummary.vue";
import CreditModal from "../UI/modals/CreditModal.vue";
export default {
  components: {
    CreditModal,
    CartSummary,
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val;
      document.dir = this.$i18n.messages[this.$i18n.locale].direction;
      document.documentElement.lang = this.$i18n.locale;
      this.$vuetify.locale.current = this.$i18n.locale;
    },
  },
  data() {
    return {
      isCreditModalOpen: false,
      locale: this.$i18n.locale,
    };
  },
  methods: {
    async logOutUser() {
      try {
        await this.logout();
      } catch (error) {
        console.log(error);
      } finally {
        window.location.reload(true);
      }
    },
    openMobileSideBar() {
      this.$store.commit("setIsOpenMobileSideBar", true);
    },
    // async updateUserLanguageInDB(language) {
    //   if (this.isAuth) {
    //     const res = await this.$store.dispatch(
    //       "AuthModule/updateLanguageInDB",
    //       language
    //     );
    //     if (res.status === 200) {
    //       let userDetails = JSON.parse(localStorage.getItem("user"));
    //       userDetails = {
    //         ...userDetails,
    //         language: res.data.language,
    //       };
    //       localStorage.setItem("user", JSON.stringify(userDetails));
    //       this.$store.commit("AuthModule/setUserLang", res.data.language);
    //     }
    //   }
    // },
    ...mapActions({
      logout: "AuthModule/logout",
    }),
  },
  computed: {
    positionStyle() {
      if (
        this.$route.name === "Category" ||
        (this.$route.name === "home" && this.isDesktop)
      )
        return "footer-position";
      return "";
    },
    rotleinLogo() {
      return "https://dolimo.co.il/wp-content/uploads/2022/01/Component-246-–-1.png";
    },
    dolimoLogo() {
      return "https://dolimo.co.il/wp-content/uploads/2020/10/credit.svg";
    },
    contactImgUrl() {
      return "https://dolimo.co.il/wp-content/uploads/2022/01/contact-person.png";
    },
    ...mapGetters({
      isAuth: "AuthModule/isAuthenticated",
      userName: "AuthModule/getUserName",
      customerName: "AuthModule/getCustomerName",
      isMobile: "isMobile",
      isDesktop: "isDesktop",
    }),
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  .summary-btn {
    border: 1px $controlPanelMainColor solid;
    border-radius: 20px;
    height: 30px;
    font-size: 16px;
    box-shadow: unset;
    color: $controlPanelMainColor;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1490196078);
  }
}
#footer-container {
  bottom: 0px;
  width: 100%;
  z-index: 0;
}

.footer-position {
  position: fixed;
}

#footer-bottom-part-text-container i:lang(en) {
  display: flex;
  margin: unset;
  margin-right: 15px;
  float: left !important;
  p {
    font-size: $extraSmallTextSize;
  }
}

footer {
  margin-top: 0px;
}
#need-help-container:lang(en) {
  border-radius: 10px 10px 10px 0px;
}
#need-help-container {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-column-gap: 15px;
  align-items: center;
  background: $primaryBackgroundButtonColorRevers;
  padding: 5px 20px;
  border-radius: 10px 10px 0 10px;
  h2 {
    font-size: $mediumTextSize;
    font-weight: 100;
    margin: 10px 0;
    color: white;
  }
  a {
    color: white;
    border: 1px white solid;
    padding: 5px 15px;
    border-radius: 8px;
    font-size: $extraSmallTextSize;
  }
}
#contact-container {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 30px;
  // align-items: center;
  justify-items: end;
}
.credit {
  // background: linear-gradient(42deg, #a8d47d 0%, #88c052 33%, #6daf2d 100%);
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  font-size: 20px;
  color: #1c1c1c;
  text-decoration: underline;
  font-size: $extraSmallTextSize;
  cursor: pointer;
  &:hover {
    color: #1f1fbb;
  }
  &:focus {
    background: unset;
  }
}
@media only screen and (max-width: 601px) {
  //Mobile

  //lang section start

  .user-short-details {
    p {
      text-align: center;
      margin: 2px 0;
      font-weight: 100;
      &:nth-child(2) {
        font-weight: bold;
      }
    }
  }
  .user-icon {
    padding: 15px;
    background-color: $primaryBackgroundColor;
    border-radius: 15px;
  }
  .horizontal-divider {
    padding-left: 20px;
  }
  /*  .horizontal-divider:after {
    content: "|";
    margin: 0 15px;
  }*/
  .language-icon {
    margin: 0;
    margin-left: 5px;
  }
  .lang-container {
    justify-content: center;

    a {
      font-size: $smallTextSize !important;
      vertical-align: unset;
      color: $primaryTextColor !important;
    }
  }
  .col.s12.user-short-details {
    margin-top: 20px;
  }

  //lang section end

  h2 {
    font-size: $mediumTextSize !important;
  }
  a {
    font-size: $extraSmallTextSize !important;
  }
  #footer-bottom-part {
    margin-top: 20px;
  }
  #footer-bottom-part-container {
    // grid-template-columns: 1fr !important;
    // padding: 10px;
    // align-items: center;
    // justify-items: center;
    p {
      font-size: 13px !important;
    }
    // #footer-bottom-part-logos-container {
    //   justify-self: unset;
    // }
  }
}
@media only screen and (max-width: 993px) {
  //Big tablets and down
  //tablets
  #contact-container {
    grid-template-columns: 1fr;
    grid-template-columns: 100% !important;
    grid-template-rows: 1fr 1fr;
  }
  #need-help-container {
    grid-template-columns: 50% 50% !important;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: unset;
  }
  #need-help-container div:nth-child(2),
  #need-help-container div:nth-child(3) {
    grid-row-start: 2;
  }
  #need-help-container div:nth-child(3) {
    justify-self: left;
  }
}
@media only screen and (min-width: 768px) and (max-width: 993px) {
  #need-help-container {
    width: 50%;
    // grid-template-columns: 25% 25% 25% 25% !important;
  }
  #need-help-container div:nth-child(3) {
    justify-self: right;
  }
}
#footer-bottom-part-logos-container img {
  height: 21px;
  width: auto;
}
img[alt="rotlein logo"] {
  margin-right: 20px;
  height: 35px !important;
}
img[alt="rotlein logo"]:lang(en) {
  margin-right: unset;
  margin-left: 20px;
}
#contact-image-container img:lang(en) {
  transform: scaleX(-1);
}
#contact-image-container img {
  height: 70px;
  width: auto;
}

#footer-bottom-part-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  p {
    margin: 5px 0;
    font-size: $smallTextSize;
  }
}
#footer-bottom-part {
  background-color: $primaryBackgroundColor;
}
#footer-bottom-part-logos-container {
  justify-self: end;
  /* align-self: center; */
}

#footer-bottom-category-container {
  background-color: $controlPanelMainColor;
  margin: 0;
}

#footer-bottom-category-container {
  display: flex;
  border-radius: 30px 30px 0px 0px;
  margin: 0;
  height: 65px;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #e4e4e4;
}
</style>
