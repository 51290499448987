<template>
  <v-btn
    v-if="this.$store.getters['AuthModule/isAuthenticated']"
    @click="clickonnav"
    :class="mode"
  >
    <svg
      class="button-overly"
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="120"
      viewBox="0 0 180 98"
    >
      <path
        id="Path_1179"
        data-name="Path 1179"
        d="M15,0H168a15,15,0,0,1,15,15h0c0,8.284-60.434,13.413-102.434,17.163S15,100,15,100A15,15,0,0,1,0,85V15A15,15,0,0,1,15,0Z"
        fill="#fff"
        opacity="0.15"
      />
    </svg>
    <div class="nav-card-continer">
      <div class="justify-space-between d-flex">
        <h2>{{ title }}</h2>
        <v-icon
          size="24"
          class="icon"
          :class="[shortWidth ? 'mr-1' : 'mr-6']"
          :icon="iconName"
        ></v-icon>
      </div>
      <div class="d-flex flex-column bottom-navcard mt-1">
        <h3>{{ subTitle }}</h3>
        <p>{{ amount }}</p>
      </div>
    </div>
  </v-btn>
</template>

<script>
import AuthService from '../../services/AuthService';

export default {
  props: {
    title: {
      type: String,
      requierd: true,
    },
    subTitle: {
      type: String,
      requierd: true,
    },
    amount: {
      type: Number,
      requierd: true,
    },
    mode: {
      type: String,
      requierd: true,
    },
    iconName: {
      type: String,
      requierd: true,
    },
    refi: {
      type: String,
      requierd: true,
    },
    shortWidth: {
      type: Boolean,
      requierd: false,
      defult: true,
    },
  },
  data() {
    return {
      refer: this.ref,
    };
  },
  computed: {
    iconSrc() {
      return `/images/${this.iconName}.svg`;
    },
  },
  methods: {
    clickonnav() {
      // console.log("goto: /" + this.$props.refi);
      // console.log(this.$props);
      this.$store.commit("setMenuToOpen");
      this.$router.replace("/" + this.$props.refi);

      if (this.$route.path.includes(this.$props.mode)) {
        AuthService.updateHeaderCounters();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.button-overly {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}
.icon {
  // margin-right: 25px;
}

.controlPanel {
  background: $controlPanelNavCardColor;
}

.orders {
  background: $ordersNavCardColor;
}

.customers {
  background: $customersNavCardColor;
}
a:lang(en) {
  text-align: left;
}
button {
  height: fit-content !important;
  min-width: 180px !important;
  padding: 13px;
  text-align: right;
  border-radius: 15px;
  box-shadow: unset;
  margin: 0 15px;
  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 100;
    margin-bottom: 10px;
    letter-spacing: 0px;
    .icon {
      margin-right: 24px;
      margin-bottom: -5px;
    }
    .icon:lang(en) {
      font-size: 14px;
      margin-right: 0px !important;
      margin-left: 7px;
    }
  }
  h3 {
    font-size: $extraSmallTextSize;
    font-weight: 300;
    margin: 0;
    line-height: 14.22px;
    letter-spacing: 0px;
    display: inline;
  }
  p {
    margin: 0;
    font-size: 20px;
    color: black;
    display: inline;
    margin-left: 5px;
  }
  p:lang(en) {
    margin: unset;
    margin-right: 5px;
  }
}
@media (max-width: 601px) {
  h2 i.material-icons {
    margin-right: 10px !important;
  }
  a {
    margin: 0;
    padding: 8px 15px;
    h2 {
      margin: 0;
      margin-bottom: 5px;

      font-size: $smallTextSize;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 993px) {
  a {
    margin: 0 4px;
  }
}

.nav-card-continer {
  min-width: 180px;
  color: white;

  h3 {
    font-size: $mediumTextSize;
    font-weight: 500;
  }

  h2 {
    font-weight: 400;
  }
}

.bottom-navcard {
  color: black;
}
</style>
