<template>
  <div class="inner-box" :class="[cartClass]">
    <p v-if="title" class="inner-box-title">{{ title }}</p>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    iscart: {
      type: Boolean,
      default: false,
    },
    fontWeight: {
      type: Number,
      default: 500,
    },
  },
  computed: {
    cartClass() {
      return this.iscart ? "cart" : "";
    },
  },
};
</script>

<style scoped lang="scss">
.inner-box-title {
  font-size: $mediumTextSize;
  font-weight: v-bind(fontWeight);
  font-family: Rubik;
  line-height: 21.33px;
  text-align: right;
  margin-bottom: 10px;
}

.inner-box {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.inner-box.cart {
  background-color: transparent;
  padding: 0;
  // min-width: 420px;
  height: fit-content !important;
}

@media (max-width: 601px) {
  .inner-box.cart {
    min-width: 100%;
  }

  .inner-box {
    min-width: 100%;
    max-width: unset;
  }
}
</style>
