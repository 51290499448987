<template>
  <div>
    <div class="customers-page">
      <TommorowOrdersCustsTable
        @getCustomersPagination="getCustomersPagination"
        :customersList="getCustomerSortedListByTommorowOrderMade"
        :count="getCountCustomers"
      />
    </div>
  </div>
</template>

<script>
import TommorowOrdersCustsTable from "../helpers/TommorowOrdersCustsTable.vue";
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
  components: {
    TommorowOrdersCustsTable,
  },
  data() {
    return {};
  },
  // watch: {
  //   "$route.query"() {
  //     this.initFilter();
  //   },
  //   "$route.params"() {
  //     this.initFilter();
  //   },
  // },
  // mounted() {
  //   this.initFilter();
  // },
  methods: {
    async getCustomersPagination(obj) {
      await this.$store.dispatch("Customers/getCustomersPagination", obj);
      // this.initFilter();
    },
    // initFilter() {
    //   this.filteredCustomerList = this.getCustomers;
    //   const categoriesKeys = Object.keys(this.$route.query);
    //   const paramsKeys = Object.keys(this.$route.params);

    //   for (let categoryKey of categoriesKeys) {
    //     this.filterCustomers(categoryKey, this.$route.query[categoryKey]);
    //   }

    //   for (let paramKey of paramsKeys) {
    //     this.filterCustomers(paramKey, this.$route.params[paramKey]);
    //   }
    // },
    // filterCustomers(categoryKey, categoryValue) {
    //   let filteredCustomers = this.filteredCustomerList;
    //   let newFilteredCustomers = [];
    //   newFilteredCustomers = filteredCustomers.filter((cust) => {
    //     let isFiltered = false;

    //     switch (categoryKey) {
    //       case "distLine":
    //         isFiltered = cust.DistLine.linekey === parseInt(categoryValue);
    //         break;
    //       case "agentId":
    //         isFiltered = cust.Agent.agent === parseInt(categoryValue);
    //         break;
    //       case "custId":
    //         isFiltered = cust.cust === parseInt(categoryValue);
    //         break;
    //       case "status":
    //         isFiltered = (cust.isActive ? 1 : 0) === parseInt(categoryValue);
    //         break;
    //       case "custType":
    //         if (categoryValue === "allcustomers") {
    //           isFiltered = true;
    //         } else if (categoryValue === "appcustomers") {
    //           isFiltered = cust.isInApp;
    //         }
    //         break;
    //     }
    //     return isFiltered;
    //   });
    //   this.filteredCustomerList = newFilteredCustomers;
    // },
    isCustomerHasOrderForTomorrow(customer) {
      const tomorrow = moment().add(1, "days").format("DD/MM/YYYY");
      const lastOrderDate = moment(customer.lastOrderSenddate).format(
        "DD/MM/YYYY"
      );
      return lastOrderDate === tomorrow;
    },
  },
  computed: {
    getCustomerSortedListByTommorowOrderMade() {
      const copyCustomerList = [...this.getCustomers];
      const sortedCCustomers = copyCustomerList.sort((a, b) => {
        const aHasOrderTomorrow  = this.isCustomerHasOrderForTomorrow(a);
        const bHasOrderTomorrow  = this.isCustomerHasOrderForTomorrow(b);
        return Number(aHasOrderTomorrow) - Number(bHasOrderTomorrow);
      });
      return sortedCCustomers;
    },
    ...mapGetters({
      getCustomers: "Customers/getCustomers",
      // getCustomersDistLines: "Customers/getCustomersDistLines",
      getCountCustomers: "Customers/getCustomersCount",
    }),
  },
};
</script>

<style scoped lang="scss">
//style for page with scope
.customers-page {
  padding: 15px !important;
}

@media only screen and (max-width: 601px) {
  .button-container {
    gap: 10px;
    margin-bottom: 20px !important;
    flex-direction: column !important;
  }

  .customers-page {
    padding: 10px !important;
    margin-top: 10px !important;
    width: 100vw;
    // max-width: calc(100vw - 40px);
  }
  .mobile-nav-buttons {
    // margin-right: 15px;
  }
  .date-filters {
    gap: 20px !important;
  }
}

.customers-page {
  margin-top: 50px;
}


// @media only screen and (max-width: 768px) {
//   .customers-page {
//     padding: 10px !important;
//   }
// }

.modal-content {
  font-size: $mediumTextSize;
  color: $modalContentColor;
  font-weight: 400;
  line-height: 21.33px;
  text-align: center;
  margin-top: -20px;

  .email {
    color: #36b079;
  }
}
.finances-page {
  margin-right: 20px;
  padding-bottom: 50px;
  padding-right: 40px;
  padding-left: 40px;
}

.date-filters {
  display: flex;
  gap: 40px;
}
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}
</style>
