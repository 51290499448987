<template>
  <div class="mb-6">
    <div class="mt-3 invoices-filters d-flex">
      <v-radio-group hide-details v-model="invoiceFilterMode">
        <v-radio
          hide-details
          label="סכימת כל החשבניות"
          value="allInvoices"
        ></v-radio>
        <v-radio
          hide-details
          label="סכימת חשבוניות פתוחות"
          value="openInvoices"
        ></v-radio>
      </v-radio-group>
      <v-switch
        inset
        color="#801512"
        v-model="isfilterOpenInvoicesOn"
        hide-details
        class="invoice-checkbox"
        label="סינון חשבוניות פתוחות"
      ></v-switch>
    </div>
  </div>
  <v-table fixed-header class="fixed-footer-table" height="500px">
    <thead>
      <th>{{ $t(`invocies.table.columns.invoiceDate`) }}</th>
      <th>{{ $t(`invocies.table.columns.dueDate`) }}</th>
      <th>{{ $t(`invocies.table.columns.backLogDays`) }}</th>
      <th>{{ $t(`invocies.table.columns.order`) }}</th>
      <th>{{ $t(`invocies.table.columns.invoice`) }}</th>
      <th>{{ $t(`invocies.table.columns.details`) }}</th>
      <th>{{ $t(`invocies.table.columns.price`) }}</th>
      <th>{{ $t(`invocies.table.columns.download`) }}</th>
    </thead>
    <tbody>
      <invoices-table-tr
        :invoiceFilterMode="invoiceFilterMode"
        :invoicesList="filterInvoices"
        @updateCustomSum="updateCustomSum"
      />
    </tbody>
    <tfoot>
      <th class="sum-title">
        {{ this.customSum.length > 0 ? "סכום מסומן" : 'סה"כ' }}
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th class="sum-value font-weight-normal">₪{{ sum }}</th>
      <th></th>
    </tfoot>
  </v-table>
</template>

<script>
import moment from "moment";
import { truncateText } from "@/utilities.js";
import InvoicesTableTr from "./InvoicesTableTr.vue";

export default {
  components: { InvoicesTableTr },
  props: {
    tableMode: {
      type: String,
      default: null,
    },
    invoicesList: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      customSum: [],
      invoiceFilterMode: "openInvoices",
      isfilterOpenInvoicesOn: true,
    };
  },
  watch: {
    invoiceFilterMode(val) {
      if (val === "openInvoices") {
        this.isfilterOpenInvoicesOn = true;
      } else {
        this.isfilterOpenInvoicesOn = false;
      }
    },
    isfilterOpenInvoicesOn(val) {
      if (val) {
        this.invoiceFilterMode = "openInvoices";
      } else {
        this.invoiceFilterMode = "allInvoices";
      }
    },
  },
  computed: {
    filterInvoices() {
      if (this.isfilterOpenInvoicesOn) {
        return this.invoicesList.filter((invoice) => invoice.isOpen);
      } else {
        return this.invoicesList;
      }
    },
    sum() {
      if (this.customSum.length > 0) {
        return this.customSum
          .reduce((acc, cur) => acc + cur, 0)
          .toLocaleString();
      }
      return 0;
      // if (!this.invoicesList) return 0;
      // const sum = this.invoicesList.reduce((acc, cur) => {
      //   const price = cur.totalprice < 0 ? cur.totalpric * -1 : cur.totalprice;
      //   return acc + price;
      // }, 0);
      // return sum.toLocaleString();
    },
  },
  methods: {
    updateCustomSum(sumValue) {
      this.customSum = sumValue;
    },
    async downloadInvoice(docId) {
      this.isLoading = true;
      await this.$store.dispatch("Invoices/delevires", docId);
      this.isLoading = false;
    },
    calculateBacklogDays(invoice) {
      return invoice.duedate
        ? moment().diff(moment(invoice.duedate, "DD/MM/YYYY"), "days")
        : "";
    },
    truncateText(text) {
      return truncateText(text, 100);
    },
    formatDate(senddate) {
      return moment(senddate).format("DD/MM/YY");
    },
  },
};
</script>

<style lang="scss">
.invoices-filters {
  .v-label {
    color: black !important;
    font-size: $mediumTextSize;
  }
}

.v-table__wrapper {
  overflow-x: scroll;
  max-height: 500px;
}
</style>
<style scoped lang="scss">
.fixed-footer-table {
  position: relative;
}
.fixed-footer-table tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
@media only screen and (max-width: 601px) {
  thead th {
    font-size: 15px !important;
    min-width: 100px;
  }
  tbody td {
    font-size: 15px !important;
    min-width: 100px;
  }

  .sum-title,
  .sum-value {
    font-size: 15px !important;
  }
}
thead {
  border-radius: 0px 50px 50px 0px;
  background-color: #f5f5f5;
  position: sticky;
  th {
    font-size: $mediumTextSize;
    font-weight: 400;
    padding: 10px 0px;

    &:first-child {
      border-radius: 0px 50px 50px 0px;
    }
    &:last-child {
      border-radius: 50px 0px 0px 50px;
    }
  }
}
tfoot {
  border-radius: 0px 50px 50px 0px;
  background-color: #f5f5f5;
  color: #868686;
  th {
    font-size: $mediumTextSize;
    font-weight: 700;
    padding: 10px 0px;
    min-width: 150px;

    &:first-child {
      border-radius: 0px 50px 50px 0px;
    }
    &:last-child {
      border-radius: 50px 0px 0px 50px;
    }
  }
  .font-weight-normal {
    font-weight: 400;
  }
}
</style>
