<template>
  <transition-group
    v-if="isOpenMobileSideBar || isLaptop || isDesktop"
    tag="div"
    name="menu-open-mobile"
  >
    <slot></slot>
    <v-btn
      v-if="isOpenMobileSideBar"
      rounded="pill"
      variant="outlined"
      class="close-mobile-side-bar-btn pr-4 pl-4"
      size="large"
      :color="closeBtnColor"
      @click="closeMobileSideBar"
      >אישור</v-btn
    >
  </transition-group>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.cjs.js";

export default {
  props: {
    closeBtnColor: {
      type: String,
      default: "#801512",
    },
  },
  methods: {
    closeMobileSideBar() {
      this.$store.commit("setIsOpenMobileSideBar", false);
    },
  },
  computed: {
    ...mapGetters({
      isOpenMobileSideBar: "isOpenMobileSideBar",
      isTablet: "isTablet",
      isLaptop: "isLaptop",
      isDesktop: "isDesktop",
    }),
  },
};
</script>

<style scoped>
.close-mobile-side-bar-btn {
  position: fixed;
  bottom: 40px;
  left: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background: white;
}
</style>
