<template>
  <header v-if="$route.name !== 'loginPage'">
    <div class="pt-5 pb-5" :class="[headerPadding]">
      <v-container
        class="d-flex justify-space-between align-center ga-2 header-container"
      >
        <div class="d-flex align-center valign-wrapper right-side">
          <img
            class="siteLogo"
            :src="$t('Header.logourl')"
            alt="logo image"
            @click="gotoHomePage"
            v-if="
              !$store.getters.isMobile &&
              !$store.getters.isTablet &&
              !$store.getters.isLaptop &&
              $store.getters['AuthModule/isAuthenticated']
            "
          />
          <img
            class="siteLogo"
            :src="$t('Header.logourl')"
            alt="logo image"
            @click="gotoHomePage"
            v-else-if="$route.name === 'home'"
          />
          <div
            class="back-btn"
            v-else-if="
              ($store.getters.isMobile ||
                $store.getters.isTablet ||
                $store.getters.isLaptop) &&
              $store.getters['AuthModule/isAuthenticated'] &&
              $route.name !== 'home'
            "
          >
            <v-icon
              @click="$router.push('/')"
              icon="icon-back mdi-arrow-right"
            />
          </div>
          <div
            v-if="
              $route.name !== 'home' &&
              ($store.getters.isMobile ||
                $store.getters.isTablet ||
                $store.getters.isLaptop)
            "
            class="title"
          >
            {{ $t(`sideBar.${$route.name}.title`) }}
          </div>
          <div
            v-if="
              !$store.getters.isMobile &&
              !$store.getters.isTablet &&
              !$store.getters.isLaptop
            "
            class="nav-cards"
          >
            <NavCard
              :title="$t('Header.controlPanel')"
              :subTitle="$t('Header.pendingItems')"
              :amount="openAgentRequests"
              mode="controlPanel"
              iconName="mdi-face-agent"
              refi=""
            ></NavCard>
            <NavCard
              :title="$t('Header.tommorowOrders')"
              :subTitle="$t('Header.openTommorowOrders')"
              :amount="getCustomersMadeOrderForTommorowDistLineCount"
              mode="controlPanel"
              iconName="mdi-invoice-clock-outline"
              refi="tomorrowOrders/tommorowcustomers"
            ></NavCard>
            <NavCard
              :title="$t('Header.myorders')"
              :subTitle="$t('Header.activeorders')"
              :amount="openOrders"
              mode="orders"
              iconName="mdi-clipboard-text-clock"
              :refi="`orders/allorders?mainStatus=2&orderDate=${new Date().toISOString()}`"
            ></NavCard>
            <NavCard
              :title="$t('Header.cunstomers')"
              :subTitle="$t('Header.activeCustomers')"
              :amount="activeCustomers"
              mode="customers"
              iconName="mdi-account-group-outline"
              refi="customers/allcustomers"
            ></NavCard>
          </div>
        </div>

        <div class="left-align hide-on-small-only">
          <div v-if="isAuth">
            <div class="d-flex flex-column align-end">
              <div class="d-flex ga-4 align-center">
                <div class="user-short-details">
                  <p>{{ customerName }}</p>
                  <p>{{ $t("Header.hello") }}, {{ username }}!</p>
                  <a
                    v-if="isAuth && user?.agents?.length > 0"
                    @click.prevent="isOpenSelectAgentModel = true"
                    href=""
                    class="link-text"
                  >
                    {{ $t("Header.switchAgentProfile") }}
                  </a>
                  <a
                    v-if="isAuth && !$store.getters.isMobile"
                    @click.prevent="logOutUser"
                    href=""
                    class="link-text"
                  >
                    <span v-if="user?.agents?.length"> | </span>
                    {{ $t("Header.logout") }}</a
                  >
                </div>
                <v-avatar
                  v-if="!$store.getters.isMobile"
                  rounded="15"
                  color="#F8F8F8"
                  size="50"
                >
                  <img src="/images/user-icon.svg" />
                </v-avatar>
              </div>
              <div class="lang-container"></div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <AgentSelectionDialog
      :isLoading="isLoading"
      :persistent="isDialogPersistent"
      v-model="isOpenSelectAgentModel"
      :user-agents="filteredAgents || []"
      @agent-selected="selectAgent"
    />
    <v-progress-linear
      v-if="isLoading"
      color="#801512"
      :height="3"
      indeterminate
    ></v-progress-linear>
  </header>
</template>

<script>
import NavCard from "../helpers/NavCard";
import { mapGetters, mapActions } from "vuex";
import AgentSelectionDialog from "../helpers/AgentSelectionDialog";
import AuthService from "@/services/AuthService";
import { getQueryPayLoad } from "../../utilities";
// import { mapActions } from "vuex";
export default {
  components: {
    NavCard,
    AgentSelectionDialog,
  },
  data() {
    return {
      userOption: "",
      locale: this.$i18n.locale,
      cartT: this.cartTotal,
      isOpenSelectAgentModel: false,
      isDialogPersistent: false,
      // username: "איציק כהן",
    };
  },
  methods: {
    async selectAgent(agent) {
      this.loading = true;
      this.isDialogPersistent = true;
      const response = await this.$store.dispatch("AuthModule/setAgent", {
        agent: agent,
        userPhoneNumber: this.$store.getters["AuthModule/getUser"]?.phoneNumber,
      });
      if (response) {
        await AuthService.getBigData();
        if (this.$route.name === "home") {
          await this.$store.dispatch("ControlPanel/agentsList");
          await this.$store.dispatch("ControlPanel/distLinesList");
        }
        if (this.$route.name === "orders") {
          await AuthService.getBigData(
            "orders",
            getQueryPayLoad(this.$route.query, this.$route.path)
          );
        }
        if (
          this.$route.name === "customers" ||
          this.$route.name === "TomorrowOrders"
        ) {
          await AuthService.getBigData(
            "customers",
            getQueryPayLoad(this.$route.query, this.$route.path)
          );
        }
        this.isOpenSelectAgentModel = false;
        this.loading = false;
        this.isDialogPersistent = false;
        // this.$router.push({ name: "home" });
      } else {
        this.loading = false;
        this.isDialogPersistent = false;
        this.$router.push({ name: "loginPage" });
      }
    },
    async logOutUser() {
      try {
        await this.logout();
      } catch (error) {
        console.log(error);
      } finally {
        window.location.reload(true);
      }
    },
    gotoHomePage() {
      this.$store.commit("setIsShowDesktopHome", false);
      this.$router.push("/");
    },
    openMobileSideBar() {
      this.$store.commit("setIsOpenMobileSideBar", true);
    },
    ...mapActions({
      logout: "AuthModule/logout",
    }),
  },
  computed: {
    username() {
      if (this.user?.agents?.length > 0) {
        return this.user?.agents?.filter(
          (agent) => agent.agent === this.user.agent
        )[0].agentname;
      }
      return this.userName;
    },
    filteredAgents() {
      if (this.isOpenSelectAgentModel && this.user?.agents?.length > 0) {
        return this.user?.agents?.filter(
          (agent) => agent.agent !== this.user.agent
        );
      }
      return [];
    },
    headerPadding() {
      if (this.isDesktop || this.$route.name === "home") {
        return "pl-3 pr-3";
      } else if (this.isTablet || this.isMobile) {
        return "pl-3";
      }
      return "";
    },
    logoUrl() {
      return this.$t("Header.logourl");
    },
    ...mapGetters({
      isAuth: "AuthModule/isAuthenticated",
      user: "AuthModule/getUser",
      isUserRollFinance: "AuthModule/isUserRollFinance",
      userName: "AuthModule/getUserName",
      customerName: "AuthModule/getCustomerName",
      openOrders: "Orders/getOpenOrders",
      activeCustomers: "Customers/getActiveCustomers",
      getCustomersMadeOrderForTommorowDistLineCount:
        "Customers/getCustomersMadeOrderForTommorowDistLineCount",
      openAgentRequests: "AgentRequests/getOpenAgentRequests",
      isLoading: "isLoading",
      isDesktop: "isDesktop",
      isTablet: "isTablet",
      getUserRole: "AuthModule/getUserRole",
    }),
  },
  watch: {
    $route(to) {
      if (to.name !== "loginPage") {
        AuthService.updateHeaderCounters();
      }
    },
    locale(val) {
      // this.updateUserLanguageInDB(val);
      this.$i18n.locale = val;
      document.dir = this.$i18n.messages[this.$i18n.locale].direction;
      document.documentElement.lang = this.$i18n.locale;
      this.$vuetify.locale.current = this.$i18n.locale;
    },
  },
};
</script>

<style scoped lang="scss">
.v-avatar {
  border-radius: 15px;
}

.lang-container a {
  text-decoration: none;
  font-size: $smallTextSize;
}

.option-selected-conteiner {
  position: -webkit-sticky;
  position: sticky;
  top: 92.59px;
  z-index: 80;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: $primaryColorlight;
  span {
    font-size: $mediumTextSize;
  }
}
.progress {
  position: absolute;
}
.col {
  padding: unset !important;
}

// .select-wrapper .caret {
//   right: unset;
// }

.user-short-details {
  text-align: left;
  p {
    font-weight: 100;
    font-size: $smallTextSize;
    &:nth-child(2) {
      font-weight: bold;
      font-size: $mediumTextSize;
    }
  }
}

@media only screen and (max-width: 601px) {
  .user-short-details {
    text-align: center;
    line-height: 1.2;
    padding-left: 5px;
  }
}

.user-icon {
  padding: 15px;
  background-color: $primaryBackgroundColor;
  border-radius: 15px;
}
.link-text {
  text-decoration: none;
  color: $hrefLinkColor !important;
}
/*
.horizontal-divider:after {
  content: "|";
  margin: 0 15px;
}
*/
.language-icon {
  margin: 0;
  margin-left: 5px;
}
a {
  font-size: $smallTextSize;
  vertical-align: unset;
  color: $primaryTextColor !important;
}
.btn.dropdown-trigger {
  padding: unset;
  background-color: transparent;
  color: #1c1c1c;
  box-shadow: unset;
}
// .btn.dropdown-trigger:before {
//   content: "|";
//   margin: 0 10px;
// }
.material-icons.left {
  margin-right: 5px;
}
header {
  // padding: 15px 0;
  // position: fixed;
  //   top: unset;
  //   width: 100%;
  box-shadow: 0px 2px 8px #00000010;
  z-index: 2 !important;
  background-color: white;
  .container {
    padding: 10px 0 5px 0px;
  }
  .header-container {
    top: unset;
  }
}

ul {
  padding: unset;
  li {
    a {
      text-align: center;
    }
  }
}
.siteLogo:lang(en) {
  margin: unset;
  margin-right: 45px;
}
.siteLogo {
  height: 87px;
  width: auto;
  margin-left: 45px;
  cursor: pointer;
}
#header-container-left {
  justify-self: left;
}
// #header-container-left a:after {
//   content: "|";
//   margin-left: 20px;
//   margin-right: 20px;
// }

header .v-container {
  margin: 0px !important;
  max-width: 100% !important;
}

@media only screen and (max-width: 1440px) {
  .back-btn {
    background: linear-gradient(
      235.26deg,
      #6076eb 0%,
      #5784ea 40.5%,
      #22dfdd 94%,
      #1be8d9 100%
    );

    width: 66px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 10px;
    border-radius: 50px 0px 0px 50px;
    .icon-back {
      color: white;
    }
  }
  .title {
    color: $statusListColor;
    margin-right: 7px;
    font-weight: 500;
    font-size: $largTextSize;
  }
}

@media only screen and (max-width: 601px) {
  header {
    box-shadow: unset;
    position: unset;
  }
  .cart-icon {
    background: $controlPanelMainColor;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-left: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  .cart-button {
    position: relative;
  }
  .product-in-cart {
    position: absolute;
    top: -9px;
    right: -5px;
    background: $customerMainColor;
    color: white;
    padding: 0px 6px;
    font-size: 14px;
    z-index: 100;
    border-radius: 50%;
  }
  header .v-container {
    min-height: 90px;
    // margin-right: 10px;
  }
  .siteLogo {
    // height: 40px;
    //margin-right: 25px !important;
  }
  .siteLogo:lang(en) {
    margin-right: 10px;
  }
  .siteLogo.favicon {
    height: 87px;
    width: 110px;
    padding: 5px;
  }
  header {
    padding: 0;
  }
  .orders-nav-card {
    margin-right: 5px;
  }
  .cart-nav-card {
    margin-left: 5px;
  }
  .cart-nav-card:lang(en) {
    margin-left: 0px;
    margin-right: 5px;
  }
  .orders-nav-card {
    margin-right: 0px;
  }

  .right-side {
    justify-content: space-between !important;
    padding-bottom: 0px !important;

    .v-col {
      padding-bottom: 0px !important;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 993px) {
  .nav-cards {
    display: flex;
    overflow: scroll;
  }
  .valign-wrapper {
    justify-content: flex-end;
  }
  .right-side {
    padding-bottom: 0px !important;
  }
  .siteLogo {
    margin-left: 10px;
  }
}
</style>
