<template>
  <Home v-if="this.isDekstop || this.isShowDesktopHome" />
  <NavigationPage v-else />
</template>

<script>
import { mapGetters } from "vuex";
import Home from "../pages/Home.vue";
import NavigationPage from "../pages/NavigationPage.vue";

export default {
  name: "ResponsiveHome",
  components: {
    Home,
    NavigationPage,
  },
  data() {
    return {};
  },
  watch: {
    isShowDesktopHome() {
      this.checkDeviceType();
    },
  },
  methods: {
    checkDeviceType() {
      if (this.isDekstop || this.isShowDesktopHome) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  unmounted() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
  computed: {
    ...mapGetters({
      isDekstop: "isDesktop",
      isLaptop: "isLaptop",
      isTablet: "isTablet",
      isShowDesktopHome: "isShowDesktopHome",
    }),
  },
};
</script>
