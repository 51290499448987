<template>
  <div
    :style="{ 'height': isBtnExpand ? hightExpand : '' }"
    class="table-line-wrapper"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    isBtnExpand: {
      type: Boolean,
      default: false,
    },
    hightExpand: {
      type: String,
      default: "",
      required: false,
    },
  },
};
</script>
<style scoped lang="scss">
.table-line-wrapper {
  font-weight: 600;
  background: transparent;
  border: 1px solid $grayBorderColor;
  padding: 20px;
  border-radius: 20px;
  &.large-line {
    height: 250px;
  }
}
</style>
