<template>
  <div class="table-container">
    <div class="table-header">
      <div class="table-header-title d-flex ga-2">
        <v-icon :class="[mode]" :icon="icon" />
        <span>{{ title }}</span>
      </div>
      <div
        class="d-flex ga-2 align-center"
        style="line-height: 0.7"
        :class="[isMobile ? 'flex-column ga-0' : '']"
      >
        <div class="sum-count">
          <span>
            סה"כ
            <span class="sum-content" :class="[mode]">{{ sumCount }}</span>
            {{ sumText }}
          </span>
        </div>
        <div v-if="ordersSum" class="sum-count">
          <span>
            סכום
            <span class="sum-content" :class="[mode]"
              >₪{{ Number(ordersSum.toFixed(2)).toLocaleString() }}</span
            >
          </span>
        </div>
      </div>
    </div>
    <div class="table-lines">
      <slot v-if="sumCount > 0"> </slot>
      <NotFound v-else class="mt-5" title="לא נמצאו נתונים..." />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NotFound from "./NotFound.vue";

export default {
  components: { NotFound },
  props: {
    title: {
      type: String,
    },
    mode: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
    },
    sumCount: {
      type: Number,
      default: 0,
    },
    ordersSum: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
    }),
    sumText() {
      switch (this.mode) {
        case "customer":
          return "לקוחות";
        case "order":
          return "הזמנות";
        case "controlPanel":
          return "בקשות";
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.table-container {
  position: relative;
  border: 1px solid $grayBorderColor;
  border-radius: 20px;
  margin-bottom: 20px;
}

.table-header {
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 20px;
  font-weight: 500;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  .sum-count {
    font-size: $mediumTextSize;
  }
}

@media only screen and (max-width: 601px) {
  .table-header {
    font-size: $mediumTextSize;
  }

  .sum-count {
    font-size: 17px !important;
  }
}

.table-lines {
  // padding: 20px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  // overflow-y: scroll;
  max-height: 600px;
}

@media only screen and (max-width: 1024px) {
  .table-lines {
    // overflow-x: scroll;
    // margin-left: 10px;
    max-height: 60vh;
  }
}

@media only screen and (max-width: 768px) {
  .table-lines {
    // overflow-x: scroll;
    // margin-left: 10px;
  }
}

.sum-content.customer,
.table-header-title.customer,
.table-header-title .v-icon.customer {
  color: $customerMainColor;
}

.sum-content.order,
.table-header-title.order,
.table-header-title .v-icon.order {
  color: $ordersMainColor;
}

.sum-content.controlPanel,
.table-header-title.controlPanel,
.table-header-title .v-icon.controlPanel {
  color: $controlPanelMainColor;
}
</style>
