<template>
  <modal-container
    :gap="'0px'"
    :borderRadius="'20px'"
    :padding="'20px'"
    :maxWidth="'400px'"
  >
    <Backdrop
      v-if="openModal"
      @close-model="$emit('closeModal')"
      :bgColor="'#00000061'"
    >
    </Backdrop>
    <div class="content w-100">
      <div class="d-flex justify-space-between align-center">
        <div class="title-wrapper">
          <v-avatar color="#f5f5f5" icon="mdi-account" size="50"></v-avatar>
          <span class="black font-16 font-weight-bold">{{ agentName }}</span>
        </div>
        <v-icon
          icon="mdi mdi-close-circle-outline"
          class="mb-2"
          color="#757575"
          @click="$emit('closeModal')"
        />
      </div>
      <div>
        <span class="black font-18 font-weight-bold">שינוי יעד חודשי</span>
      </div>
      <div class="d-flex ga-5 justify-space-between">
        <div class="previous-value-warpper">
          <div class="font-weight-bold">ערך קודם</div>
          <div class="gray font-18 previous-value">
            ₪{{ monthlyGoal ?? 0 }}
          </div>
        </div>
        <div class="new-value-warpper">
          <div class="font-weight-bold">ערך חדש</div>
          <input
            ref="new-value"
            class="new-value"
            v-model="newMonthlyGoal"
            type="number"
            width="164px"
            placeholder="₪0"
            inputmode="numeric"
          />
        </div>
      </div>
      <div>
        <v-btn
          @click="sendNewMonthlyGoal"
          class="acssept-btn"
          width="361px"
          height="50px"
        >
          אישור
        </v-btn>
      </div>
    </div>
  </modal-container>
</template>

<script>
import Backdrop from "../backdrop.vue";
import ModalContainer from "./modal-container.vue";
export default {
  components: { ModalContainer, Backdrop },
  emits: ["closeModal", "updateNewAgentMonthlyGoal"],
  data() {
    return {
      newMonthlyGoal: 0,
    };
  },
  props: {
    openModal: {
      type: Boolean,
      defult: false,
    },
    monthlyGoal: {
      type: Number,
    },
    agentName: {
      type: String,
    },
  },
  methods: {
    sendNewMonthlyGoal() {
      if (this.newMonthlyGoal <= 0) {
        this.$store.dispatch("ShowToast", {
          message: "יש להקליד ערך גדול מאפס",
          status: "error",
        });
        return;
      }
      if (this.newMonthlyGoal === this.monthlyGoal) {
        this.$store.dispatch("ShowToast", {
          message: "יש להקליד ערך שונה מהערך הקודם",
          status: "error",
        });
        return;
      }
      this.$emit("updateNewAgentMonthlyGoal", this.newMonthlyGoal);
    },
  },
  mounted() {
    this.$refs["new-value"].focus();
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
}
.title-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  .icon-backgorund {
    background: $primaryBackgroundColor;
    padding: 15px;
    border-radius: 50%;
  }
}
.previous-value-warpper {
  width: 164px;
  text-align: start;
  .previous-value {
    background: $grayBackgroundColor;
    padding: 10px 15px;
    border-radius: 10px;
  }
}
.new-value-warpper {
  text-align: start;
  .new-value {
    border: 1px solid $grayText;
    border-radius: 10px;
    padding: 10px;
    max-width: 164px !important;
    height: 50px !important;
    background: $grayBackgroundColor;
    box-shadow: inset 0px 0px 10px 0px rgba(79, 185, 137, 0.450980392);
  }
}

.acssept-btn {
  color: #36B079 !important;
}
</style>
