<template>
  <div class="agent-requests">
    <AgentRequestsTable :agentRequestsList="filteredAgentRequests" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AgentRequestsTable from "../helpers/AgentRequestsTable.vue";
import moment from "moment";

export default {
  components: { AgentRequestsTable },
  data() {
    return {
      filteredAgentRequests: [],
      intervalId: null,
    };
  },
  async mounted() {
    this.initFilter();
    // this.intervalId = setInterval(async () => {
    //   await this.$store.dispatch("AgentRequests/agentRequestsIntervalUpdate");
    // }, 60000);
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
  watch: {
    "$route.query"() {
      this.initFilter();
    },
  },
  methods: {
    initFilter() {
      this.filteredAgentRequests = this.agentRequests;
      const categoriesKeys = Object.keys(this.$route.query);
      if (categoriesKeys.length === 0) {
        this.filteredAgentRequests = this.agentRequests;
      } else {
        for (let categoryKey of categoriesKeys) {
          this.filterRequests(categoryKey, this.$route.query[categoryKey]);
        }
      }
    },
    filterRequests(categoryKey, categoryValue) {
      let filterRequests = this.filteredAgentRequests;
      let newFilteredRequests = [];
      newFilteredRequests = filterRequests.filter((req) => {
        let isFiltered = false;
        switch (categoryKey) {
          case "fromDate":
            isFiltered = moment(req.date).isSameOrAfter(
              moment(categoryValue),
              "day"
            );
            break;
          case "toDate":
            isFiltered = moment(req.date).isSameOrBefore(
              moment(categoryValue),
              "day"
            );
            break;
          case "custId":
            isFiltered = req.cust === parseInt(categoryValue);
            break;
          case "agentId":
            isFiltered = req?.Agent?.agent === parseInt(categoryValue);
            break;
        }
        return isFiltered;
      });
      this.filteredAgentRequests = newFilteredRequests;
    },
  },
  computed: {
    ...mapGetters({
      agentRequests: "AgentRequests/getAgentRequests",
    }),
  },
};
</script>

<style scoped lang="scss">
.agent-requests {
  margin-top: 50px;
}

@media only screen and (max-width: 1024px) {
  .agent-requests {
    padding: 0px 10px;
  }
}
</style>
