<template>
  <div class="filter-button" :class="[borderColorClass, exapndClass]">
    <button
      @click="clickOnFilterBtn"
      class="d-flex ga-4 d-flex ga-4 pr-3 pt-3 pl-3 w-100 justify-space-between"
      :class="[btnPadding]"
    >
      <span>{{ selectedOption?.name }}</span>
      <v-slide-x-reverse-transition leave-absolute hide-on-leave>
        <v-icon
          size="25"
          v-if="!isPanelExpand && !isOptionChoosen"
          color="black"
          icon="mdi mdi-chevron-down"
        />
        <v-icon
          size="25"
          v-else-if="!isOptionChoosen"
          color="black"
          icon="mdi mdi-chevron-up"
        />
      </v-slide-x-reverse-transition>
      <v-slide-y-transition leave-absolute hide-on-leave>
        <v-icon size="25" v-if="isOptionChoosen" icon="mdi mdi-close" />
      </v-slide-y-transition>
    </button>
    <div v-if="showSearchBar && isPanelExpand" class="pa-2">
      <v-text-field
        v-model="searchText"
        density="compact"
        placeholder="חיפוש חופשי"
        hide-details
        persistent-placeholder
        variant="underlined"
      ></v-text-field>
    </div>
    <v-scroll-x-transition leave-absolute hide-on-leave>
      <div v-if="isPanelExpand" class="options">
        <div class="pt-1 pb-1 pr-4 pl-4"><v-divider></v-divider></div>
        <v-list-item
          rounded="pill"
          class="choice-select font-18"
          v-for="(catrgory, index) in filterCategories"
          :key="catrgory.id"
          @click="selectChoice(catrgory)"
          :title="truncateText(catrgory.name)"
        >
          <v-divider
            v-if="index !== Categories.length - 1"
            class="ma-0"
          ></v-divider>
        </v-list-item>
      </div>
    </v-scroll-x-transition>
  </div>
  <div v-if="isPanelExpand" style="height: 60px"></div>
</template>

<script>
import { truncateText } from "@/utilities.js";
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: Object,
    title: {
      type: String,
    },
    showSearchBar: {
      type: Boolean,
      default: false,
    },
    Categories: {
      type: Array,
    },
    mode: {
      type: String,
    },
    queryKey: {
      type: String,
    },
    defaultValue: {
      type: String,
    },
  },
  data() {
    return {
      isPanelExpand: false,
      isOptionChoosen: false,
      searchText: "",
      selectedOption: {
        name: `סינון לפי ${this.defaultValue}`,
      },
    };
  },
  watch: {
    modelValue() {
      this.selectChoice(this.modelValue);
    },
  },
  computed: {
    filterCategories() {
      const searchTerm = this.searchText.trim();

      if (searchTerm.length > 0) {
        return this.Categories.filter((category) => {
          return Object.values(category).some((value) =>
            String(value).includes(searchTerm)
          );
        });
      }
      return this.Categories;
    },
    exapndClass() {
      return this.isPanelExpand ? "expand" : "";
    },
    borderColorClass() {
      if (this.isOptionChoosen) return this.mode + "-choosen";
      return "";
    },
    btnPadding() {
      if (this.isPanelExpand) return "pr-3 pt-3 pl-3";
      return "pa-3";
    },
    borderRadius() {
      if (this.isPanelExpand) return "20px";
      return "50px";
    },
    cartClass() {
      return this.iscart ? "cart" : "";
    },
  },
  methods: {
    truncateText(text) {
      return truncateText(text, 35);
    },
    clickOnFilterBtn() {
      if (this.isOptionChoosen) {
        this.isPanelExpand = false;
      } else {
        this.isPanelExpand = !this.isPanelExpand;
      }
      this.isOptionChoosen = false;
      this.selectedOption = { name: `סינון לפי ${this.defaultValue}` };
      let query = { ...this.$route.query };
      delete query[this.queryKey];
      this.$router.push({ path: `${this.$route.path}`, query: query });
    },
    selectChoice(selectChoice) {
      if (!selectChoice) {
        this.selectedOption = {
          name: `סינון לפי ${this.defaultValue}`,
        };
        this.isOptionChoosen = false;
        this.isPanelExpand = false;
        this.searchText = "";
        return;
      }
      this.selectedOption = selectChoice;
      this.$emit("update:modelValue", selectChoice);
      this.isOptionChoosen = true;
      this.isPanelExpand = false;
      let query = { ...this.$route.query };
      query[this.queryKey] = selectChoice?.id;
      if (query?.mainStatus && query?.status) {
        delete query.mainStatus;
      }
      this.$router.push({ path: `${this.$route.path}`, query: query });
    },
  },
};
</script>

<style lang="scss">
.options {
  overflow-y: scroll;
  max-height: 200px;
}

.options::-webkit-scrollbar {
  display: none;
}

.options {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-icon {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.filter-button {
  border: 1px solid $borderColor;
  border-radius: v-bind(borderRadius);
  font-size: $mediumTextSize;
  width: fit-content;
  color: $tableLabelColor;
  background-color: white;
}

.filter-button.expand {
  position: absolute;
  z-index: 1;
}

.filter-button.customers-choosen {
  border: 1px solid $customerMainColor;
  color: $customerMainColor;
  .v-icon {
    color: $customerMainColor;
  }
}

.filter-button.orders-choosen {
  border: 1px solid $ordersMainColor;
  color: $ordersMainColor;
  .v-icon {
    color: $ordersMainColor;
  }
}

.filter-button.agentRequests-choosen {
  border: 1px solid $controlPanelMainColor;
  color: $controlPanelMainColor;
  .v-icon {
    color: $controlPanelMainColor;
  }
}

.choice-select {
  cursor: pointer;
  color: $tableLabelColor !important;
}
</style>
