<template>
  <v-btn
    flat
    :loading="isLoading"
    @click="downloadInvoice()"
    icon="mdi-file-download"
  ></v-btn>
</template>

<script>
export default {
  props: {
    docId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async downloadInvoice() {
      this.isLoading = true;
      await this.$store.dispatch("Invoices/delevires", this.docId);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
