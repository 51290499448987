import Api from "./Api";

export default {
  createOrderInDB(order) {
    return Api().post("createOrderInDB", order);
  },
  getOrders(obj) {
    return Api().post("orders", obj);
  },
  getOrdersPagination(obj) {
    const payload = {
      ...obj,
    };
    if (obj?.mainStatus) {
      payload.status = obj.mainStatus;
      delete payload.mainStatus;
    }
    return Api().post("orders-pagination", payload);
  },
  getOrder(ordname) {
    return Api().post("order", ordname);
  },
  getOrdersCount() {
    return Api().get("open-orders-count");
  },
  updateOrder(obj) {
    return Api().post("updateOrder", obj);
  },
  updateOrderLine(obj) {
    return Api().post("orderline", obj);
  },
  removeOrderLine(obj) {
    return Api().post("removeorderline", obj);
  },
  sendOrderToPriority(order) {
    return Api().post("send-order-to-priority", order);
  },
  printOrder(ordName) {
    return Api().get("print-procedure", {
      params: { fieldName: ordName, procedureName: "WWWSHOWORDER" },
      responseType: "blob",
    });
  },
  printDelivery(docNum) {
    return Api().get("print-procedure", {
      params: { fieldName: docNum, procedureName: "NIRR_WWWSHOWDOC_D" },
      responseType: "blob",
    });
  },
  printDeliveryEnglish(docNum) {
    return Api().get("print-procedure", {
      params: { fieldName: docNum, procedureName: "NIRM_WWWSHOWDOC_D" },
      responseType: "blob",
    });
  },
  newOrder(obj) {
    return Api().post("newOrder", obj);
  },
};
