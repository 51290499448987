<template>
  <v-dialog
    class="agent-selection-dialog"
    :persistent="persistent"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <v-card
      v-if="userAgents.length > 0"
      rounded="xl"
      max-width="500"
      origin="center"
      title="בחירת  סוכן"
    >
      <v-card-text>
        <span>אנא בחר בסוכן כדי להתחבר</span>
        <div class="d-flex ga-5 w-100 justify-center align-center mt-2 mb-2">
          <div class="d-flex ga-5" v-if="!isLoading">
            <div
              v-for="userAgent of userAgents"
              :key="userAgent.agent"
              @click="handleAgentSelect(userAgent.agent)"
              class="pa-5 d-flex flex-column justify-center align-center agent-choice"
            >
              <v-icon color="#434343" size="70" icon="mdi-account"></v-icon>
              <span>{{ userAgent.agentname }}</span>
            </div>
          </div>
          <div v-else>
            <div
              class="pa-5 d-flex flex-column justify-center align-center agent-choice"
            >
              <span class="loader"></span>
              <span>טוען...</span>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AgentSelectionDialog",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    userAgents: {
      type: Array,
      required: true,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "agent-selected"],
  methods: {
    handleAgentSelect(agent) {
      this.$emit("agent-selected", agent);
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-choice {
  border: 1px solid #cccccc;
  border-style: dashed;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: #cccccc;
  }
}

.v-dialog > .v-overlay__content,
.v-dialog > .v-overlay__content > form {
  align-items: center;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #000000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
