<template>
  <div @click="$router.go(-1)" class="cursor-pointer back-button">
    <v-icon color="#6d1106" icon="mdi-arrow-right" />
    <span class="back-button-label">חזרה להזמנות</span>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.back-button {
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
}
.back-button-label {
  font-size: 20px;
  color: $ordersMainColor;
}
</style>
