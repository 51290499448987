import AuthService from "@/services/AuthService";
export default {
  namespaced: true,
  state: () => ({
    isLoggedIn: false,
    isAuthFailed: false,
    user: null,
    token: null,
  }),
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.isLoggedIn = !!user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setAuthFailed(state, failed) {
      state.isAuthFailed = failed;
    },
  },
  actions: {
    async updateLanguage({ state, commit }, lang) {
      try {
        const res = await AuthService.changeLang({
          phone: state.user.phone,
          language: lang,
        });
        if (res.success) {
          commit("setUser", { ...state.user, language: lang });
        }
        return res;
      } catch (err) {
        console.error("Failed to update language:", err);
        throw err;
      }
    },
    async setAgent(context, dataObj) {
      try {
        const response = await AuthService.setAgent(dataObj);
        const token = response.data.token;
        const user = response.data.user;
        await context.dispatch("logout");
        context.commit("setToken", token);
        context.commit("setUser", user);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        return true;
      } catch (err) {
        console.error("Failed to set agent:", err);
        return false;
      }
    },
    async login({ commit }, { token, user }) {
      commit("setToken", token);
      commit("setUser", user);
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
    },
    async tryLogin({ commit, dispatch }, dataType = null) {
      try {
        const token = localStorage.getItem("token");
        const userDetails = JSON.parse(localStorage.getItem("user"));

        if (token && userDetails) {
          commit("setToken", token);
          commit("setUser", userDetails);

          const getBigDataSucceeded = await AuthService.getBigData(dataType);
          if (!getBigDataSucceeded) {
            console.warn("Failed to get big data, logging out");
            await dispatch("logout");
          }
        }
      } catch (error) {
        console.error("Error during login attempt:", error);
        commit("setAuthFailed", true);
      }
    },
    async logout({ commit }) {
      try {
        await AuthService.logout();
        commit("setUser", null);
        commit("setToken", null);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      } catch (error) {
        console.error("Error during logout:", error);
      }
    },
    async setUser({ commit }, user) {
      commit("setUser", user);
    },
  },
  getters: {
    isUserRollFinance: (state) => state.user?.userRoll === "finance",
    isUserRollNormal: (state) => state.user?.userRoll === "normal",
    getCustomerName: (state) => state.user?.Customer?.custdes,
    getUserLang: (state) => state.user?.language,
    getCustomerData: (state) => state.user?.Customer,
    getToken: (state) => state.token,
    getUserName: (state) => state.user?.name,
    getUser: (state) => state.user,
    getUserRole: (state) => state?.user?.UserRoll,
    getUserPhone: (state) => state.user?.phone,
    getUserEmail: (state) => state.user?.email,
    isAuthenticated: (state) => !!state.token,
  },
};
